<template>
	<a-card class="guest-container">
		<template #title>
			<div v-show="appInfoAlert === 'open'">
				<a-alert message="应用列表" type="info" show-icon closable @close="closeAlert" style="margin-bottom: 10px">
					<template #icon><bulb-outlined style="margin-top: 20px" /></template>
					<template #description>
						<div class="alertContent">
							管理员可以在当前页面管理已经添加的所有应用，应用可以实现单点登录和数据同步能力。
							<br />
							当添加完应用后，应该确认应用处于启用状态，并已经完成了授权。在应用详情中，可以看到应用的详细信息、单点登录地址、子账户配置、同步配置、授权、审计等信息
						</div>
					</template>
				</a-alert>
			</div>

			<div class="header">
				<a-button type="primary" @click="handleAdd">新增应用</a-button>
				<a-form layout="inline" style="margin-top: 10px">
					<a-form-item label="应用名称">
						<a-input allow-clear v-model:value="name" placeholder="请输入应用名称" />
					</a-form-item>
					<a-form-item label="应用类型">
						<a-select mode="multiple" v-model:value="appTypeList" style="width: 207px">
							<a-select-option :value="key" v-for="(item, key) in appTypeListMapping" :key="key"> {{ item }} </a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="应用分类">
						<a-select ref="select" v-model:value="appCategory" style="width: 207px">
							<a-select-option v-for="item in appCategoryList" :key="item.code" :value="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item>
						<a-button type="primary" @click="getDataList(1)">查询</a-button>
						<a-button style="margin-left: 10px" @click="reset">重置</a-button>
					</a-form-item>
				</a-form>
			</div>
		</template>
		<a-table
			:loading="loading"
			:columns="columns"
			rowKey="id"
			:data-source="tableData"
			:pagination="false"
			:expandedRowKeys="expandArr"
			:expandIconColumnIndex="-1"
			@expand="expandValue"
			:scroll="{
				x: 800,
			}"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'icon'">
					<span>
						<img v-if="record.icon" style="width: 50px; height: 50px" :src="record.icon" alt="" />
						<span v-else></span>
					</span>
				</template>
				<template v-if="column.key === 'status'">
					<span>
						<el-popconfirm
							confirm-button-text="确认"
							cancel-button-text="取消"
							title="确认修改应用状态吗"
							@confirm="confirmSwitch(record)"
							@cancel="cancelSwitch(record)"
						>
							<template #reference>
								<el-switch @click="clickSwitch(record)" v-model="record.status" />
							</template>
						</el-popconfirm>
					</span>
				</template>
				<template v-if="column.key === 'code'">
					<span>
						{{ record.code ? record.code : "--" }}
					</span>
				</template>
				<template v-if="column.key === 'appType'">
					<span>
						{{ appTypeListMapping[record.appType] }}
					</span>
				</template>
				<template v-if="column.key === 'appPlanName'">
					<span>
						{{ record.appPlan.planName ? record.appPlan.planName : "--" }}
					</span>
				</template>
				<template v-if="column.key === 'action'">
					<span>
						<a @click="expandRow('info', record)" style="margin-right: 20px">
							详情
							<caret-up-outlined v-show="record.isDown" />
							<caret-down-outlined v-show="!record.isDown" />
						</a>
						<!-- <a
              @click="appAlert(record)"
              v-show="record.appType == 'OMS'"
              style="margin-right: 20px"
            >
              关联应用
            </a>
            <a
              @click="intoApp(record)"
              v-show="record.appType == 'OMS'"
              style="margin-right: 20px"
            >
              打开应用
            </a> -->
						<template
							v-if="
								record.refreshToken && record.refreshTokenExpire && Math.floor((record.refreshTokenExpire - Date.now()) / 1000 / 60 / 60 / 24) <= 7
							"
						>
							<OauthBtn :record="record" :reOauth="true" />
						</template>
					</span>
				</template>
			</template>
			<template #expandedRowRender="{ record }">
				<div class="cardBox">
					<a-row style="width: 100%" :gutter="16">
						<a-col :span="6">
							<a-card title="应用信息" class="cardMiniBox" size="small">
								<p class="cardContent">card content</p>
								<p class="cardP">
									<a style="color: #1890ff" @click="changeDiagle('info', record)"> 查询详情 </a>
									<a @click="handleEdit(record)" style="color: gray"> 修改应用 </a>
									<a style="color: gray">删除应用</a>
								</p>
							</a-card>
						</a-col>
						<!-- <a-col :span="6"
              ><a-card title="认证信息" class="cardMiniBox" size="small">
                <p class="cardContent">应用的单点登录地址</p>
                <p class="cardP">
                  <a type="text" style="color: #1890ff">IDaas发起地址</a>
                </p>
              </a-card></a-col
            >
            <a-col :span="6">
              <a-card title="账户信息" class="cardMiniBox" size="small">
                <p class="cardContent">平台主账户与应用系统中子账户的关联表</p>
                <p class="cardP">
                  <a type="text" style="color: #1890ff">查看应用子账户</a>
                </p>
              </a-card></a-col
            > -->
						<a-col :span="6">
							<a-card title="授权信息" class="cardMiniBox" size="small">
								<p class="cardContent">应用与人员组织的授权关系</p>
								<p class="cardP">
									<a type="text" style="color: #1890ff" @click="toAppAuth"> 授权 </a>
								</p>
							</a-card>
						</a-col>
						<a-col :span="6">
							<a-card title="管理应用内权限" class="cardMiniBox" size="small">
								<p class="cardContent">管理应用内菜单与功能权限</p>
								<p class="cardP">
									<a type="text" style="color: #1890ff" @click="openAuth(true)"> 绑定权限系统 </a>
								</p>
							</a-card>
						</a-col>
						<a-col v-if="record.appCategory == 'SHOP'" :span="6">
							<!-- 快递 -->
							<AccountShipping :parentInfo="record"></AccountShipping>
						</a-col>
						<a-col v-if="record.appCode == 'OMS'" :span="6">
							<!-- 仓库 -->
							<!-- <OmsWarehouseCard :parentInfo="record"></OmsWarehouseCard> -->
						</a-col>

						<a-col v-if="record.appCode == 'OMS'" :span="6">
							<!-- <OmsShopCard :parentInfo="record"></OmsShopCard> -->
						</a-col>

						<a-col v-if="record.appCode == 'OMS'" :span="6">
							<!-- 快递 -->
							<!-- <OmsShipping :parentInfo="record"></OmsShipping> -->
						</a-col>
						<a-col v-if="record.appCategory == 'TMS'" :span="6">
							<!-- 快递 -->
							<TmsShipping :parentInfo="record"></TmsShipping>
						</a-col>
						<a-col v-if="record.appCategory == 'TMS'" :span="6">
							<!-- 快递 -->
							<TmsAccount :parentInfo="record"></TmsAccount>
						</a-col>
						<a-col v-if="record.appCode == 'SCM'" :span="6">
							<!-- 快递 -->
							<ScmSupplier :parentInfo="record"></ScmSupplier>
						</a-col>
					</a-row>
				</div>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="page_no"
			v-model:pageSize="page_size"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
		<a-drawer width="640" placement="right" v-model:visible="dialogVisible" @close="changeDiagle" :closable="false">
			<p class="pStyleTitle">应用详情({{ appInfo.name }})</p>
			<div class="pStyle">
				<IdaasDescription title="图标" text="a" :url="appInfo.icon" />
				<IdaasDescription title="应用编号" :text="appInfo.code" />
				<IdaasDescription title="应用名称" :text="appInfo.name" />
				<IdaasDescription title="应用类型" :text="appInfo.appType" />
				<IdaasDescription title="应用状态" :text="appInfo.status === 0 ? '禁用' : '启用'" />
				<a-divider />
				<IdaasDescription title="clientId" :text="appInfo.appClient && appInfo.appClient.clientId">
					<a-button size="small" @click="handleOuath(appInfo)" style="margin-left: 20px; font-size: 12px" :disabled="ouathDisable">
						{{ ouathDisable ? timeText + "秒" : appInfo.appClient && appInfo.appClient.clientId ? "重新生成密钥" : "生成密钥" }}
					</a-button>
				</IdaasDescription>
				<IdaasDescription title="创建人" :text="appInfo.appClient && appInfo.appClient.createdUser" />
				<IdaasDescription title="创建时间" :text="appInfo.appClient && appInfo.appClient.createdAt" />
				<IdaasDescription title="修改人" :text="appInfo.appClient && appInfo.appClient.updatedUser" />
				<IdaasDescription title="最后一次修改时间" :text="appInfo.appClient && appInfo.appClient.lastUsedAt" />
				<!-- 不展示 clientId -->
				<!-- <IdaasDescription title="clientIds" :arr="appInfo.clientIds" /> -->
				<div v-if="appInfo.appPlan ? appInfo.appPlan.planName : ''">
					<a-divider />
					<IdaasDescription title="付费计划" :text="appInfo.appPlan.planName" />
					<IdaasDescription title="code" :text="appInfo.appPlan.planCode" />
					<IdaasDescription title="价格" :text="appInfo.appPlan.price" />
					<IdaasDescription title="状态" :text="appInfo.appPlan.status === 0 ? '禁用' : '启用'" />
					<IdaasDescription title="试用天数" :text="appInfo.appPlan.trialDuration" />
					<IdaasDescription title="试用次数" :text="appInfo.appPlan.trialTimes" />
					<IdaasDescription title="付费类型" :text="appInfo.appPlan.payType" />
				</div>

				<a-divider />
				<IdaasDescription title="app过期时间" :text="appInfo.accessTokenExpire" />
				<IdaasDescription title="token过期时间" :text="appInfo.appExpire" />
				<IdaasDescription title="refreshTokenExpire过期时间" :text="appInfo.appKey" />
			</div>
		</a-drawer>
		<a-drawer width="640" placement="right" v-model:visible="appAuthVisible" @close="openAuth(false)" :closable="false">
			<p class="pStyleTitle">绑定权限系统（云安全访问服务）</p>
			<a-form layout="inline" style="margin-top: 10px">
				<a-form-item label="权限系统名称">
					<a-select placeholder="请选择权限系统" ref="select" v-model:value="appData.merchantApp.authSysCode" style="width: 120px">
						<a-select-option v-for="item in authSysList" :value="item.code" :key="item.code">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" @click="saveMerchantApp">提交</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
		<!-- 密钥弹框 -->
		<a-modal v-model:visible="visibleSecret">
			<template #title><span class="secretStyle">密钥信息</span></template>
			<template #footer>
				<a-button type="primary" @click="handleSecret">复制数据</a-button>
			</template>
			<p>
				<span class="secretStyle">clientId:</span>
				{{ secret.clientId }}
				<copy-outlined @click="copy(secret.clientId)" />
			</p>
			<p>
				<span class="secretStyle">clientSecret:</span>
				{{ secret.clientSecret }}
				<copy-outlined @click="copy(secret.clientSecret)" />
			</p>
		</a-modal>
		<a-modal v-model:visible="secretConfirm">
			<template #title><span class="secretStyle">密钥信息</span></template>
			<template #footer>
				<a-button type="primary" @click="secretConfirm = !secretConfirm"> 确定 </a-button>
			</template>
			<p>当前数据只展示一次，请复制并粘贴</p>
		</a-modal>
		<a-modal v-model:visible="selectApp" :width="'800px'" title="关联应用列表">
			<!-- //appList -->
			<template #footer>
				<a-button type="primary" @click="selectApp = !selectApp">关闭</a-button>
			</template>
			<a-table :columns="selectAppColumns" rowKey="id" :scroll="{ y: 300 }" :data-source="tableDataAlert" :pagination="false" @expand="expandValue">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'icon'">
						<span>
							<img v-if="record.icon" style="width: 50px; height: 50px" :src="record.icon" alt="" />
							<span v-else></span>
						</span>
					</template>
					<template v-if="column.key === 'bound'">
						<span>
							{{ record.bound ? "已关联" : "未关联" }}
						</span>
					</template>
					<template v-if="column.key === 'action'">
						<span>
							<a-button @click="toOMS(record)" :disabled="record.bound" style="margin-right: 20px"> 关联 </a-button>
						</span>
					</template>
				</template>
			</a-table>
		</a-modal>
	</a-card>
</template>

<script>
import { onMounted, reactive, ref, toRefs, computed } from "vue"
// import axios from "@/common/axios";
import { useRouter, useRoute } from "vue-router"
import useCopy from "@/hooks/useCopy"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
// import { message } from "ant-design-vue";
import { localGet, localSet } from "@/common/utils"
import IdaasDescription from "@/components/IdaasDescription.vue"
import { message } from "ant-design-vue"
// import OmsShopCard from "./cardList/OmsShopCard.vue";
// import OmsWarehouseCard from "./cardList/OmsWarehouseCard.vue";
// import OmsShipping from "./cardList/OmsShipping.vue";
import TmsShipping from "./cardList/TmsShipping.vue"
import TmsAccount from "./cardList/TmsAccount.vue"
import ScmSupplier from "./cardList/ScmSupplier.vue"
import AccountShipping from "./cardList/AccountShipping.vue"
import OauthBtn from "./components/OauthBtn.vue"
export default {
	name: "Application",
	components: {
		IdaasDescription,
		// OmsShopCard,
		// OmsWarehouseCard,
		// OmsShipping,
		TmsShipping,
		TmsAccount,
		ScmSupplier,
		AccountShipping,
		OauthBtn,
	},
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const copy = useCopy
		const state = reactive({
			appCategoryList: JSON.parse(localStorage.getItem("appTypeList")),
			appDes: "", //保存当前点击详情时的平台
			nowDate: new Date().getTime(),
			loading: false,
			merchantAppId: "",
			appName: "",
			expandArr: [],
			tableData: [], // 数据列表
			tableDataAlert: [], // 数据列表
			selectList: [], // 选中项
			ouathDisable: false,
			timeText: 60,
			columns: [
				{
					title: "应用图标",
					dataIndex: "icon",
					key: "icon",
				},
				{
					title: "应用名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "应用类型",
					dataIndex: "appType",
					key: "appType",
				},
				{
					title: "应用分类",
					dataIndex: "appCategory",
					key: "appCategory",
				},
				{
					title: "应用模板",
					dataIndex: "appCode",
					key: "appCode",
				},
				{
					title: "付费计划",
					dataIndex: "appPlanName",
					key: "appPlanName",
				},
				{
					title: "应用状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "操作",
					key: "action",
					width: 100,
				},
			],
			selectAppColumns: [
				{
					title: "应用图标",
					dataIndex: "icon",
					key: "icon",
				},
				{
					title: "应用名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "应用状态",
					dataIndex: "bound",
					key: "bound",
				},
				{
					title: "操作",
					key: "action",
				},
			],
			ruleForm: {
				appPlan: "",
			},
		})
		onMounted(() => {
			try {
				if (route.query.type == "reAuth") {
					let data = {
						type: route.query.type || "",
						id: route.query.id,
						code: route.query.code || "",
						shopId: route.query.shopId || "",
					}
					reAuthApp(data)
				}
			} catch {
				console.log("savgApplication")
			}
			getDataList()
			getauthoritySystemList()
		})
		const params = reactive({
			appCategory: "",
			appTypeList: [],
			name: "",
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, //存储请求时的pagesize
		})
		const reset = () => {
			params.name = ""
			params.appCategory = ""
			params.total = 0
			params.page_no = 1
			params.page_size = 10
			params.old_page_size = 10
			getDataList()
		}
		// 获取列表
		const getDataList = (pageNo) => {
			state.nowDate = new Date().getTime()
			state.loading = true
			pageNo && (params.page_no = pageNo)
			const data = {
				...params,
				// ext: true,
				merchantId: store.getters["user/merchantId"],
			}
			delete data.total
			axios
				.post(api.getMerchantAppList, data)
				.then((res) => {
					state.tableData = res.list
					state.tableData.forEach((item) => {
						item.status = item.status === 1 ? true : false
						if (!item.appPlan) {
							item.appPlan = {
								appId: null,
								createdAt: null,
								createdUser: "",
								id: null,
								payType: "",
								planCode: "",
								planDisplayName: "",
								planName: "",
								price: null,
								status: null,
								trialDuration: null,
								trialTimes: null,
								updatedAt: null,
								updatedUser: "",
							}
						}
						if (!item.appClient) {
							item.appClient = {
								clientId: "",
								clientSecret: "",
								createdAt: null,
								createdUser: "",
								id: null,
								lastUsedAt: null,
								merchantAppId: null,
								status: null,
								updatedAt: null,
								updatedUser: "",
							}
						}
					})
					params.total = res.total
					params.page_no = res.page_no
					params.page_size = res.page_size
					params.old_page_size = res.page_size
					state.loading = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 当前页 及当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = params.old_page_size === pageSize ? page : 1
			getDataList()
		}
		// 新增
		const handleAdd = () => {
			router.push({ name: "addApplication" })
		}
		// 编辑
		const handleEdit = (id) => {
			router.push({
				name: "saveApplication",
				query: {
					type: "edit",
					value: JSON.stringify(id),
					code: id.code,
				},
			})
		}
		const appListData = reactive({
			appList: ["oms"],
		})
		//intoApp
		const intoApp = (value) => {
			// getAccessInfo
			let data = {
				merchantAppId: value.id,
			}
			axios.post(api.getAccessInfo, data).then((res) => {
				let url = res.url + `#/login?token=${res.accessToken}`
				if (res.accessToken && res.url) {
					window.open(url)
				}
			})
		}
		const reAuthApp = (value) => {
			// getAccessInfo
			let data = {
				merchantId: store.getters["user/merchantId"],
				code: value.code,
				merchantAppId: value.id,
			}
			axios.post(api.reAuthApp, data).then((res) => {
				console.log(res)
				message.success("操作成功")
			})
		}
		//关联应用弹框
		const appAlert = (value) => {
			if (!value.appClient.clientId) {
				message.warning("当前应用没有密钥，请点击详情生成密钥")
				return
			}

			state.merchantAppId = value.id
			let data = {
				merchantId: store.getters["user/merchantId"],
				merchantAppId: value.id,
				bindAppType: "LAZADA",
				targetAppIdBound: "",
				// targetAppId: 0,// 暂时没用
			}

			axios
				.post(api.merchantAppListBindabl, data)
				.then((res) => {
					state.tableDataAlert = res
					diagleDate.selectApp = true
				})
				.catch((err) => {
					console.log(err)
				})
		}
		// 关联应用
		const toOMS = (value) => {
			// appBindInfo
			let data = {
				merchantAppId: state.merchantAppId,
				bindAppId: value.id,
			}
			axios
				.post(api.merchantAppBind, data)
				.then((res) => {
					if (res === true) {
						message.success("关联成功")
					}
					diagleDate.selectApp = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		//倒计时
		const timer = () => {
			state.timeText = 60
			let timer = setInterval(() => {
				state.ouathDisable = true
				if (state.timeText == 0) {
					state.ouathDisable = false
					clearInterval(timer)
				} else {
					state.timeText--
				}
			}, 1000)
		}
		// 生成密钥
		const handleOuath = (value) => {
			timer()
			let data = {
				merchantAppId: value.id,
			}
			axios
				.post(api.saveAppClient, data)
				.then((res) => {
					diagleDate.visibleSecret = true
					diagleDate.secretConfirm = true
					diagleDate.secret.clientSecret = res.clientSecret
					diagleDate.secret.clientId = res.clientId
					state.loading = false
					changeDiagle("ouathInfo", value)
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 详情模板 控制页面显示隐藏
		const diagleDate = reactive({
			dialogVisible: false, //查看详情
			appAuthVisible: false, //绑定系统
			appInfo: {},
			appInfoAlert: localGet("appInfoAlert") || "open",
			visibleSecret: false,
			secretConfirm: false,
			selectApp: false, //控制选择应用的弹框
			secret: { clientId: "", clientSecret: "" },
		})
		const handleSecret = () => {
			let data = `clientId : ${diagleDate.secret.clientId} ; clientSecret : ${diagleDate.secret.clientSecret}`
			copy(data)
		}
		const closeAlert = () => {
			diagleDate.appInfoAlert = "close"
			localSet("appInfoAlert", "close")
		}
		const changeDiagle = (type, value) => {
			if (type === "info" || type === "ouathInfo") {
				let data = {
					merchantAppId: value.id,
				}
				axios
					.post(api.getMerchantAppDetail, data)
					.then((res) => {
						state.tableData.forEach((item) => {
							if (item.id === res.merchantAppResVO.id) {
								item.appClient = res.merchantAppResVO.appClient
							}
						})
						diagleDate.appInfo = res.merchantAppResVO
						type === "ouathInfo" ? "" : (diagleDate.dialogVisible = !diagleDate.dialogVisible)
					})
					.catch((err) => {
						console.log(err)
					})
			} else {
				diagleDate.dialogVisible = false
			}
		}
		const expandValue = (type, value) => {
			if (type) {
				state.expandArr.push(value.id)
			} else {
				state.expandArr = state.expandArr.filter((item) => {
					return item != value.id
				})
			}
		}
		const expandRow = (type, value) => {
			console.log("appDes", value)
			state.appDes = value
			//merchantAppData赋值，用于绑定
			merchantAppData.nowData = value

			if (state.expandArr.length > 0) {
				if (state.expandArr[0] !== value.id) {
					state.expandArr = []
					state.expandArr.push(value.id)
				} else {
					state.expandArr = []
				}
			} else {
				state.expandArr.push(value.id)
			}
			state.tableData.forEach((item) => {
				if (item.id === value.id && state.expandArr.length > 0) {
					item.isDown = true
				} else {
					item.isDown = false
				}
			})
		}
		// 详情中的授权操作，跳转应用授权页面
		const toAppAuth = () => {
			router.push({ name: "appAuth" })
		}
		// 打开系统权限授权弹框。
		const openAuth = (value) => {
			diagleDate.appAuthVisible = value
		}
		const merchantAppData = reactive({
			nowData: {}, //存储当前点击的数据
			appData: {
				merchantApp: {
					appExpire: "",
					appId: "",
					appPlanId: "",
					id: "",
					merchantId: "",
					name: "",
					icon: "",
					authSysCode: "",
				},
				code: "",
			},
			authSysList: [],
		})
		// 保存系统权限
		const saveMerchantApp = () => {
			if (!merchantAppData.appData.merchantApp.authSysCode) {
				message.error("请选择权限系统")
				return
			}
			merchantAppData.appData.merchantApp.appExpire = merchantAppData.nowData.appExpire
			merchantAppData.appData.merchantApp.appId = merchantAppData.nowData.appId
			merchantAppData.appData.merchantApp.appPlanId = merchantAppData.nowData.appPlanId
			merchantAppData.appData.merchantApp.id = merchantAppData.nowData.id
			merchantAppData.appData.merchantApp.merchantId = merchantAppData.nowData.merchantId
			merchantAppData.appData.merchantApp.name = merchantAppData.nowData.name
			merchantAppData.appData.merchantApp.icon = merchantAppData.nowData.icon
			merchantAppData.appData.code = merchantAppData.nowData.icon
			let data = merchantAppData.appData
			axios.post(api.saveMerchantApp, data).then(() => {
				message.success("保存成功")
				openAuth(false)
			})
		}
		const getauthoritySystemList = () => {
			let data = {
				merchantId: store.getters["user/merchantId"],
			}
			axios.post(api.authoritySystemList, data).then((res) => {
				merchantAppData.authSysList = res
			})
		}
		const confirmSwitch = (value) => {
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})

			try {
				if (value.accessIpList && value.accessIpList.indexOf(",") > -1) {
					value.accessIpList = JSON.parse(value.accessIpList)
					value.accessIpList = value.accessIpList.split(",")
				}
			} catch {
				console.log("err")
			}

			let data = {
				merchantApp: { ...value },
				code: value.code,
			}

			axios.post(api.saveMerchantApp, data).then(() => {
				message.success("修改成功")
			})
		}
		const cancelSwitch = () => {}
		const clickSwitch = (value) => {
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
		}

		const appTypeListMapping = computed(() => store.state.appTypeListMapping)

		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			...toRefs(appListData),
			...toRefs(merchantAppData),
			multipleTable,
			getDataList,
			handleAdd,
			handleEdit,
			confirmSwitch,
			cancelSwitch,
			clickSwitch,
			expandRow,
			handleOuath,
			expandValue,
			handleSecret,
			handleChangePage,
			closeAlert,
			changeDiagle,
			copy,
			toOMS,
			appAlert,
			intoApp,
			reAuthApp,
			reset,
			toAppAuth,
			saveMerchantApp,
			openAuth,
			getauthoritySystemList,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			appTypeListMapping,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding-bottom: 10px;
}
.a-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.cardBox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: row;
	.ant-card-head-title {
		font-size: 12px;
	}
	// .cardMiniBox {
	//   width: 20%;
	// }
}
:deep(.ant-card) {
	width: 100% !important;
	height: 145px;
	font-size: 12px;
	margin: 8px 10px;
	.ant-card-head {
		font-size: 12px;
		padding: 0 16px;
	}
	.ant-card-body {
		padding-bottom: 0;
		padding: 16px;
		.ant-btn {
			font-size: 12px;
		}
		.cardP {
			margin-bottom: 0;
			a {
				margin-right: 18px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.cardContent {
			color: #909399;
			height: 40px;
		}
	}
}
.alertContent {
	font-size: 12px;
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
.secretStyle {
	font-weight: 900;
	margin-right: 20px;
}
.copy:hover {
	cursor: pointer;
}
</style>
